@import '../../styles/mixins';

.loader {
  margin: auto;
  margin-top: 30%;
  width: 423px;
  height: 270px;
  border-radius: 10px;
  background: #1d1d41;
  padding: 30px 15px 15px;
  position: relative;
  border: none !important;

  @include sm {
    width: 360px;
    margin-top: 50%;
  }

  .closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #fff;
    margin-bottom: 6.68px;
  }

  .text {
    font-weight: 500;
    font-size: 15px;
    line-height: 22.5px;
    color: #fff;
    margin-bottom: 47px;
  }

  .downloading {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 20px;

    svg {
      height: 100px !important;
      width: 100px !important;
      margin-top: -25px !important;
    }
  }

  .progressDiv {
    position: relative !important;
    margin-bottom: 45px;

    .progressBar {
      width: 100%;
      height: 15px;
      border-radius: 100px;
      background: #3f3f5d;
    }

    .protsentChecker {
      display: flex;
      align-items: center;
      gap: 7px;
      position: absolute !important;
      right: 0;
      top: -24px;

      p {
        font-weight: 600;
        font-size: 15px;
        line-height: 22.5px;
        color: #fff;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
}
