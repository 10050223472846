@import 'styles/mixins';

.container {
  background: #141332;
  padding-top: 126px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  @include sm {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .blurImgR {
    position: absolute;
    bottom: -500px;
    right: -400px;
    z-index: 0;
  }
  .blurImgL {
    position: absolute;
    top: -500px;
    left: -400px;
    z-index: 0;
  }
  .logo {
    position: absolute;
    top: 34px;
    right: 34px;
    display: flex;
    cursor: pointer;
    @include sm {
      position: static;
      z-index: 9;
    }
  }

  .form {
    z-index: 1;
    @include sm {
      width: 100%;
    }
  }

  .logo,
  .blurImgR,
  .blurImgL {
    user-select: none;
    outline: none;
  }
}
