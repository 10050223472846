@import 'styles/mixins';

.header {
  height: 130px;
  width: 100%;
  @include align-items-center;
  gap: 16px;
  position: relative;
  overflow: hidden;

  .headerBg {
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    height: 130px;
    object-fit: cover;
  }

  .walletLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 100%;
    z-index: 3;
    padding-left: 35px;

    .logoContainer {
      width: 71.193px;
      height: 71.193px;
      background-color: rgba(255, 255, 255, 0.22);
      stroke-width: 1px;
      border-radius: 50%;
      border: 1px solid #fff;
      z-index: 3;
    }

    .walletTxt {
      z-index: 3;

      h4 {
        color: var(--base-white);
        font-size: 12px;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 2px;
      }
      div {
        display: flex;
        align-items: center;
        gap: 16px;

        p {
          color: var(--base-white);
          font-size: 15px;
          font-weight: 500;
          line-height: 150%;
        }

        svg {
          cursor: pointer;
        }
      }
    }
  }
}
