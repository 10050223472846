.globusIcon {
  position: absolute;
  top: -129px;
  right: -60px;
  z-index: 1;

  @media (max-width: 600px) {
    display: none;
  }
}
