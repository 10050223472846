@import 'styles/mixins';

.main {
  display: flex;
  align-items: center;
  background: #1d1d41;

  .navBar {
    position: relative;
    z-index: 3;
    height: 80px;

    @include sm {
      display: none;
    }
  }

  @include sm {
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
  }

  .body {
    height: calc(100vh - 100px);
    margin-top: 60px;
    width: calc(100% - 310px);
    overflow: auto;
    border-radius: 10px;
    background: #141332;

    @include sm {
      width: 100%;
      height: 100%;
      border-radius: 0;
      overflow-x: hidden;
      margin-top: 0px;
    }
  }
}
