.container {
  max-width: 900px;
  @media (max-width: 600px) {
    max-width: 370px;
  }
  .faq {
    padding: 90px 0 110px;
    margin: 0 auto;
    max-width: 900px;
    .search {
      margin-top: 35px;
      position: relative;
      border-radius: 7px;
      border: 1.5px solid #5c5a64;
      background: rgba(255, 255, 255, 0.15);
      input {
        width: 100%;
        border: 1.5px solid #e8e8e8;
        background-color: transparent;
        border-radius: 10px;
        padding: 13px 20px;
        font-size: 15px;
        line-height: 22px;
        transition: all 0.25s ease-in-out;
        color: white;
        &:focus {
          outline: none;
          border-color: var(--primary-color);
        }
        &::placeholder {
          font-size: 15px;
          line-height: 22px;
          color: #7d8890;
        }
      }
      .icon {
        position: absolute;
        top: calc(50% - 10.5px);
        right: 20px;
      }
    }
    .accordion {
      margin-top: 30px;
      .empty {
        padding: 100px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        img {
          width: 81px;
        }
        p {
          margin-top: 24px;
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: #7d8890;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .container {
    min-height: auto;
    .faq {
      padding: 50px 0 30px;
      max-width: none;
      h2 {
        text-align: left;
      }
    }
  }
}
