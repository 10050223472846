@import 'styles/mixins';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  width: 500px;
  @include sm {
    width: 100%;
    margin-top: 77px;
    padding: 0 25px;
  }
  .title {
    color: var(--base-white);
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    @include sm {
      font-size: 20px;
      line-height: 50px;
    }
  }

  .text {
    color: var(--base-white);
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 67px;
    @include sm {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  button {
    margin-top: 100px;
    @include sm {
      margin-top: 48px;
    }
  }

  .alreadyUser {
    color: #7d8890;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    margin-top: 23px;
    @include sm {
      flex-direction: column;
    }
    span {
      color: var(--main);
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
