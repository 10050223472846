.box {
  padding: 30px 0 15px;
  width: 423px;
  height: 373px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    color: #292929;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    margin-bottom: 7px;
  }
  p {
    color: #292929;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 25px;
    text-align: center;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 15px;
    button {
      width: fit-content;
      margin-top: 18px;
    }
  }
}
