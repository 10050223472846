@import 'styles/_mixins.scss';

.container {
  @include sm {
    overflow: hidden;
    padding-right: 0;
    padding-left: 25px;

    .container {
      padding: 0;
    }
  }
}

.createBtnBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 65px;

  @include sm {
    flex-direction: column-reverse;
    gap: 15px;
    margin-bottom: 28px;
    max-width: 100%;
    overflow: hidden;
  }

  .createBtn {
    width: 222.841px;
    height: 55px;
    border-radius: 10px;
    background: var(--main, #27e6d6);
    color: #000;
    text-align: center;
    font-size: 15px;
    font-weight: 500;

    span {
      position: absolute;
      left: 13px;
      color: #141332;
      text-align: center;
      font-size: 30px;
      font-weight: 275;
    }
  }

  .nodes {
    color: var(--base-white);
    font-size: 13px;
    font-weight: 500;

    @include sm {
      font-size: 15px;
    }
  }
}

.chartHead {
  @include sm {
    flex-direction: column;
    gap: 0;
    margin-top: -10px;

    .planTxt {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 20px;
      margin-left: 0;
      text-align: left;
    }
  }
}

.chartBody {
  @include sm {
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    .infoBoxesContainer {
      display: flex;
      flex-direction: column;
      width: max-content;
      padding-right: 11px;

      .infoBoxes {
        padding-right: 11px;
      }
      .chart {
        width: 100%;
      }
    }
  }
}
