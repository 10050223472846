@import 'styles/_mixins.scss';

.elements {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 12px;

  @include sm {
  grid-template-columns: repeat(1, minmax(0, 1fr));

  }
}
