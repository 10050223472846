@import 'styles/mixins';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  width: 470px;

  @include sm {
    width: 100%;
    margin-top: 77px;
    padding: 0 25px;
  }

  .title {
    color: var(--base-white);
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 67px;
    @include sm {
      font-size: 20px;
      line-height: 50px;
      margin-bottom: 75px;
    }
  }

  .forgot {
    padding-right: 15px;
    margin-top: -17px;
    text-align: right;
    width: 100%;
    color: var(--base-white);
    font-size: 12px;
    text-decoration-line: underline;
    position: relative;
    z-index: 9;
  }

  .alreadyUser {
    color: #7d8890;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    margin-top: 23px;
    span {
      color: var(--main);
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  .button {
    margin-top: 70px;
    @include sm {
      margin-top: 48px;
    }
  }
}
