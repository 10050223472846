@import 'styles/mixins';

.form {
  width: 100%;
  padding: 35px;
  @include sm {
    padding: 25px;
  }

  .mobileText {
    display: none;
    @include sm {
      display: block;
      margin-top: 28px;
    }
  }
  .warning {
    font-size: 14px;
    line-height: 21px;
    color: #313131;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    column-gap: 10px;
    color: #fff;
    svg {
      color: #f5ba2f;
    }
    a {
      font-weight: 600;
      color: var(--main);
    }
  }
  ul {
    list-style: none;
    margin: 50px auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    li {
      position: relative;
      z-index: 2;
      width: 546px;
      transition: all 0.4s ease-in-out;
      @include sm {
        width: 100%;
      }
      .content {
        padding: 12px 21px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        border-radius: 7px;
        border: 1px solid #4d5377;
        background: #1d1d41;
        cursor: pointer;
        .info {
          display: flex;
          align-items: center;
          img {
            transition: all 0.4s ease-in-out;
          }
          p {
            color: var(--base-white);
            font-size: 15px;
            font-weight: 700;
            line-height: normal;
            margin-left: 30px;
          }
        }
        span {
          color: #3bfec4;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
  .formArea {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include sm {
      align-items: center;
    }
    .wallets {
      display: flex;
      flex-direction: column;

      span {
        &:first-child {
          @include sm {
            font-size: 18px;
            line-height: 26px;
          }
        }
        &:last-child {
          @include sm {
            display: none;
          }
        }
      }
    }

    .metamask {
      display: flex;
      align-items: center;
      gap: 12px;

      img {
        width: 25px;
        height: 25px;
        max-width: 25px;
        max-height: 25px;
        object-fit: contain;
        @include sm {
          max-width: 20px;
          max-height: 20px;
          width: 20px;
          height: 20px;
        }
      }
      svg {
        cursor: pointer;
      }
      button {
        border-radius: 10px;
        background: #2e303c;
        padding: 16px 42px;
        border: none;
        color: var(--base-white);
        font-size: 15px;
        font-weight: 600;
        line-height: normal;
        @include sm {
          padding: 12px 13px;
        }
      }
    }
  }
}
