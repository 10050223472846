.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  padding: 16px 13px;
  background: #141332;
  p {
    color: var(--base-white);
    font-size: 15px;
    font-weight: 500;
  }
  svg {
    cursor: pointer;
  }
}

.loader {
  margin: auto;
  p {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
}
