@import 'styles/mixins';

.tab {
  margin-top: 25px;
}

.tableTitle {
  color: var(--base-white);
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
}

.list {
  margin-top: 18px;
  display: grid;
  gap: 13px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.downloads {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.column {
  @include align-items-center;
  column-gap: 13px;
  .name {
    @include align-items-center;
    width: 200px;
    column-gap: 13px;
  }
  .progress {
    width: calc(100% - 200px - 80px);
  }
  .cancle {
    @include align-items-center;
    column-gap: 8px;
    font-size: 14px;
    line-height: 21px;
    width: 80px;
  }
}
