@import 'styles/_mixins.scss';

.mainContainer {
  z-index: 2;
  position: relative;
  padding: 70px 70px 370px 70px;

  @include sm {
    padding: 42px 25px 113px;
  }

  .smallDesc {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 28px;
    margin-bottom: 58px;

    @include sm {
      gap: 10px;
      margin-bottom: 45px;
    }

    .titleSection {
      width: 424px;
      height: 160px;

      @include sm {
        width: 170px;
        height: 70px;
      }

      .oceanDriveTxt {
        color: var(--base-white);
        font-size: 70px;
        font-style: normal;
        font-weight: 700;
        line-height: 80px;

        @include sm {
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 35px;
        }
      }

      .infuraTxt {
        font-size: 70px;
        font-weight: 300;
        line-height: 80px;
        color: var(--main);
        font-style: normal;

        @include sm {
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 35px;
        }
      }
    }

    .description {
      color: var(--base-white);
      font-size: 14px;
      font-weight: 275;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;

      @include sm {
        margin-bottom: 43px;
        font-size: 14px;
        line-height: normal;
        width: 328px;

        .mobileHide {
          display: none;
        }
      }
    }

    .goToBtn {
      width: 170px;
      height: 45px;
      border-radius: 8px;
      background: #16d9c9;
      color: var(--base-white);
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      white-space: nowrap;

      @include sm {
        width: 180px;
        height: 55px;
        border-radius: 10px;
        white-space: nowrap;
      }
    }
  }

  .statsDiv {
    padding-left: 85px;
    padding-right: 158px;
    padding-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100vw;
    height: 271.296px;
    border-radius: 12px 0px 0px 12px;
    background: #2e1cc8;
    opacity: 0.9;
    margin-bottom: 160px;

    @include sm {
      padding: 24px 50px 45px;
      flex-direction: column;
      width: calc(100% + 50px);
      margin-left: -25px;
      height: max-content;
      border-radius: 12px 12px 0px 0px;
      align-items: center;
      opacity: 1;
      margin-bottom: 18px;
    }

    .titleNS {
      color: var(--base-white);
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
      margin-left: -30px;
      margin-right: 17px;

      @include sm {
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 32px;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
      }
    }

    .stats {
      margin-right: 10px;
      @include sm {
        margin-bottom: 30px;
        margin-right: 0px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .statTitle {
        color: var(--base-white);
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 13px;

        @include sm {
          text-align: center;
        }
      }

      .statBox {
        width: 190.281px;
        height: 113.832px;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(255, 255, 255, 0.08) 21.35%,
          rgba(255, 255, 255, 0.13) 100%
        );
        position: relative;

        @include sm {
          width: 290px;
          height: 160px;
        }

        .statNum {
          position: absolute;
          top: 25px;
          left: 17px;
          color: var(--base-white);
          font-size: 35px;
          font-weight: 500;

          @include sm {
            top: 20px;
            left: 20px;
            font-size: 48px;
          }
        }

        .statCap {
          position: absolute;
          bottom: 4px;
          right: 12px;
          color: var(--base-white);
          text-align: right;
          font-size: 15px;
          font-weight: 500;
          line-height: 30px;

          @include sm {
            bottom: 20px;
            right: 20px;
            font-size: 20px;
          }
        }
      }
    }
  }

  .featuresContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 202px;

    @include sm {
      flex-direction: column;
      align-items: center;
      gap: 71px;
      height: max-content;
      margin-bottom: 150px;
    }

    .middleIcon {
      @include sm {
        order: -1;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: -50px;
      }
    }

    .featureCol {
      display: flex;
      flex-direction: column;
      gap: 65px;
      height: 262.29px;

      @include sm {
        width: 100%;
        gap: 71px;
        height: max-content;

        .featureBox {
          @include align-items-center;
          flex-direction: column;
        }
      }

      .featureTitle {
        color: var(--base-white);
        text-align: right;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 8px;

        @include sm {
          text-align: center;
          font-size: 24px;
          max-width: 284px;
          // margin-left: 35px;
          margin-bottom: 20px;
        }
      }
      .featureText {
        color: var(--base-white);
        text-align: right;
        font-size: 14px;
        font-weight: 275;

        @include sm {
          text-align: center;
          font-size: 15px;
        }
      }

      &:nth-of-type(3) {
        gap: 128.2px;
        @include sm {
          gap: 71px;
        }
        .featureTitle {
          text-align: left;
          @include sm {
            text-align: center;
            gap: 0;
          }
        }
        .featureText {
          text-align: left;
          @include sm {
            text-align: center;
          }
        }
      }
    }
  }

  .whatsNewContainer {
    h5 {
      color: var(--base-white);
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 75px;

      @include sm {
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 25px;
        text-align: center;
      }
    }

    .itemsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 66px;

      @include sm {
        gap: 10px;
      }

      .arrow {
        cursor: pointer;
      }

      .items {
        display: flex;
        justify-content: space-around;
        gap: 25px;

        @include sm {
          width: 100vw;
          flex-direction: row;
        }

        .item {
          width: 287.998px;
          height: 435px;
          border-radius: 12px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          background: #030221;
          cursor: pointer;

          @include sm {
            width: 246px;
            height: max-content;
          }

          img {
            width: 100%;
            height: auto;
          }

          .titleDesc {
            padding: 20px;

            @include sm {
              padding: 12px;
            }

            h6 {
              color: var(--base-white);
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 9px;
            }

            p {
              color: var(--base-white);
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
              margin-bottom: 12px;
            }

            .nextBtn {
              display: flex;
              justify-content: flex-end;
              img {
                width: 21.35px;
                height: 23px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
