@import 'styles/_mixins.scss';

.card {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #1d1d41;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: 0s ease-in-out all;
  position: relative;

  @include sm {
    width: 162px;
    height: 200px;
    padding: 10px 15px;
    align-items: center;
  }

  .title {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    color: var(--base-white);

    @include sm {
      font-size: 12px;
    }
  }

  .text {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 17px;
    height: 78.5px;

    @include sm {
      font-size: 11px;
      margin-top: 17px;
      height: 36px;
      text-align: center;
    }
  }

  .priceBox {
    margin-top: 0;
  }

  .priceText {
    color: var(--base-white);
    font-size: 22px;
    font-weight: 700;
    line-height: 23px;
    margin-top: 44px;
    height: 68px;

    @include sm {
      font-size: 16px;
      line-height: 20px;
      margin-top: 9px;
      height: 40px;
      text-align: center;
    }
  }

  .price {
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    color: var(--base-white);

    @include sm {
      font-size: 30px;
    }
  }

  .month {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #cacaca;
  }

  .list {
    margin: 0;
    padding: 0;
    margin-top: 17px;
    list-style: none;

    @include sm {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      // align-items: center;
    }

    .item {
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      color: var(--base-white);
      display: flex;
      align-items: center;
      margin: 5px 0;

      @include sm {
        font-size: 11px;
      }

      &:first-child {
        @include sm {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0;
        }
      }

      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        color: var(--main);
      }
    }
  }

  button {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.15);
    margin-top: 18px;
    border: none;

    @include sm {
      border-radius: 7px;
      height: 37px;
      width: 131px;
      color: #ffffff;
    }
  }

  &.isEnterprise {
    border-radius: 10px;
    border: 1.3px solid #fff;
    background-color: var(--main);
    .title {
      color: #000;
    }
    button {
      background-color: #1d1d41;
      &:hover {
        background-color: var(--primary-color);
      }
    }
  }
}

.disabled {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
  }

  .title,
  .month,
  .price,
  .item {
    color: #cacaca;
  }

  button {
    border: 1px solid #cacaca;
    color: #cacaca;
  }
}

.currentPlan {
  border: 3px solid purple;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
