:root {
  --primary-color: #0e42e5;
  --secondary-color: #151c52;
  --text-color: #4b4b4b;
  --main: #27e6d6;
  --base-white: #fff;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #292929;
}

* {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a:-webkit-any-link {
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

input {
  caret-color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.menu-list {
  .MuiMenuItem-root {
    &.Mui-selected {
      background: rgba(255, 255, 255, 0.1);
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
