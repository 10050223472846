@import 'styles/mixins.scss';

.box {
  padding: 18px 18px 120px;
  width: 423px;
  color: #121212;
  position: relative;
  height: 373px;
  @include sm {
    width: 100%;
  }
  h6 {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;

    text-align: center;
  }
  .close {
    position: absolute;
    right: 16px;
    top: 16px;
    color: #9e9e9e;
    cursor: pointer;
  }

  .img {
    width: 112px;
    height: 112px;
    margin: 41px auto 24px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  p {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    margin-top: 3px;
  }
  button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 6px 6px;
  }
}
