@import 'styles/mixins';

.sidebar {
  width: 290px;
  padding: 30px;
  height: 100vh;
  background: transparent;
  overflow: auto;
  color: var(--base-white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include sm {
    height: 80px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
  }

  div {
    @include sm {
      width: 100%;
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 53px;
    margin-top: 35px;

    a svg {
      display: none;
    }

    @include sm {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0;
      position: relative;
      .burgerBtn {
        display: flex;
        width: 50px;
      }

      a svg {
        width: auto;
        height: auto;
        max-width: 104px;
        max-height: 43px;
        margin-top: 8px;
        display: block;
      }
    }

    .burgerBtn {
      display: none;
      @include sm {
        display: block;
      }
    }

    a svg {
      width: 128px;
      height: 38px;
    }
  }
  .body {
    @include sm {
      display: none;
    }
    nav {
      ul {
        li {
          list-style-type: none;
          a {
            width: 230px;
            height: 55px;
            @include align-items-center;
            padding: 17px 13px;
            column-gap: 16px;
            position: relative;
            transition: 0.4s ease-in-out all;
            color: var(--base-white);
            font-size: 15px;
            font-weight: 600;
            line-height: 150%;
            &:hover,
            &.active {
              border-radius: 10px;
              background: rgba(255, 255, 255, 0.15);
              color: var(--main);
              // svg {
              //   path {
              //     fill: #27e6d6;
              //   }
              // }
              svg {
                path:first-of-type {
                  fill: #27e6d6;
                }
              }
            }
          }
        }
      }
    }
  }
  .footer {
    @include sm {
      display: none;
    }
    padding: 0 12px 5px;
    .dropFile {
      height: 55px;
      @include justify-content-center;
      color: #ffffff;
      background: #0e42e5;
      box-shadow: 5px 6px 17px rgba(0, 0, 0, 0.2);
      margin-bottom: 27px;
      padding: 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      position: relative;
      .icon {
        border: 1px solid #f1f3ff;
        box-shadow: 5px 6px 17px rgba(0, 0, 0, 0.2);
        width: 30px;
        height: 30px;
        position: absolute;
        top: calc(50% - 15px);
        right: 20px;
        background: #ffffff;
        border-radius: 50%;
        @include element-center;
        svg {
          fill: #0e42e5;
        }
      }
    }
    .logout {
      font-weight: 600;
      font-size: 15px;
      line-height: 150%;
      @include align-items-center;
      justify-content: space-between;
      color: #f1f3ff;
      cursor: pointer;
    }
  }
}

.menu {
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 75px;
  background-color: #1d1d41;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(-600px);
  transition: all 0.6s ease-in-out;
  display: none;
  @include sm {
    display: flex;
  }
  &.isOpen {
    transform: translateX(0);
  }
  .body {
    display: flex;
    flex-direction: column;
    .item {
      padding: 21px 40px;
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      line-height: normal;
      @include align-items-center;
      justify-content: space-between;

      .itemIcon {
        display: flex;
        @include align-items-center;
        gap: 20px;
      }
    }
  }
  .footerLang {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .langs {
    padding-left: 40px;
    @include align-items-center;
    gap: 16px;

    .lang {
      color: #8c8c8c;
      text-align: right;
      font-size: 15px;
      font-weight: 600;
    }

    .activeLang {
      color: #fff;
    }
  }
  .footer {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding: 21px 40px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    @include align-items-center;
    justify-content: space-between;
  }
}
