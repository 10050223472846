.table {
  &.withBorderRadiusBottom {
    border-radius: 10px;
    white-space: nowrap;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;

    thead {
      border-radius: 7px 7px 0px 0px;
      background: rgba(255, 255, 255, 0.15);
      tr {
        height: 40px;
        th {
          text-align: left;
          padding: 12px 23px;
          color: var(--base-white);
          font-size: 12px;
          font-weight: 700;
          line-height: 150%;

          &:first-child {
            border-top-left-radius: 7px;
          }

          &:last-child {
            border-top-right-radius: 7px;
          }
        }
      }
    }
    tbody {
      border-radius: 0px 0px 7px 7px;
      background: #1d1d41;
      tr {
        height: 53px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        cursor: pointer;
        &:last-child {
          border-bottom: none;

          td:first-child {
            border-bottom-left-radius: 7px;
          }

          td:last-child {
            border-bottom-right-radius: 7px;
          }
        }
        td {
          padding: 12px 23px;
          color: var(--base-white);
          font-size: 12px;
          font-weight: 700;
          line-height: 150%;
        }
      }
      tr {
        & {
          td:nth-child(3) {
            color: var(--main);
          }
        }
      }
    }

    .withBorder {
      background-color: rgba(255, 255, 255, 0.15);
      tr {
        &:first-child {
          td:first-child {
            border-top-left-radius: 7px;
          }

          td:last-child {
            border-top-right-radius: 7px;
          }
        }
      }
      tr {
        & {
          td:last-child {
            text-align: right;
          }
          td:nth-child(3) {
            color: var(--main);
          }
          td:first-child {
            div {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 12px;
            }
          }
        }
      }
      tr {
        & {
          td:nth-child(4) {
            color: var(--main);
          }
          td:nth-child(3) {
            color: var(--base-white);
          }
        }
      }
    }
  }
}
