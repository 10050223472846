// @use '/src/scss/colors';

.tag {
  padding: 0 10px;
  // background-color: colors.$borders;
  border-radius: 6px;
}

.placeholder {
  color: #909eab;
  font-weight: 400;
}

.select {
  svg {
    color: var(--base-white);
  }
}
.menu {
  background-color: #1d1d41;
  padding: 0 !important;
  li {
    padding: 16px;
    color: var(--base-white);
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
  }
}
