.footer {
  background-color: transparent;
  width: 100%;
  padding: 50px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    padding: 35px 27px 50px;
    align-items: center;
    gap: 80px;
    flex-direction: column;
  }

  .descTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 11px;

    @media (max-width: 600px) {
      align-items: center;
      gap: 15px;
    }

    .desc {
      color: var(--base-white);
      font-size: 12px;
      font-weight: 300;
      line-height: 22px;

      span {
        font-weight: 600;
      }
    }
  }

  .icons {
    display: flex;
    gap: 12px;

    .iconHolder {
      width: 46.84px;
      height: 46.84px;
      flex-shrink: 0;
      border-radius: 7px;
      background: #141332;
      box-shadow: 0px 0px 22px 0px rgba(255, 255, 255, 0.32);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .reserved {
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    margin-top: -45px;
  }
}
