@import 'styles/_mixins.scss';

.whyInfuraContainer {
  padding: 75px;

  @include sm {
    padding: 25px;
  }
}

.mainGrid {
  max-width: 100%;
}

.title {
  color: var(--base-white);
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  margin-bottom: 64px !important;

  @include sm {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  span {
    color: var(--main, #27e6d6);
  }
}

.contentSection {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 57px;
  row-gap: 54px;

  @include sm {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    row-gap: 80px;
  }

  .paper {
    background-color: #141332;
    border: none;
    box-shadow: none;
    padding-right: 80px;

    @include sm {
      padding-right: 0;
    }

    svg {
      margin-bottom: 6px;

      @include sm {
        margin-bottom: 30px;
      }
    }

    .heading {
      color: var(--base-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @include sm {
        margin-bottom: 15px;
      }
    }

    .bodyText {
      color: var(--base-white);
      font-size: 14px;
      font-style: normal;
      font-weight: 275;
      line-height: normal;
    }
  }
}
